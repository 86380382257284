<template>
  <div class="flix-form-group">
    <h3 class="flix-html-h3">{{ $t('message.appointmentWebinar') }}</h3>
    <div class="flix-form-group">
      <toggle v-if="toggleWebinar" :key="toggleWebinar" :data="toggleWebinar" :onChange="function (data) { changeWebinar(data) }">
        <template v-slot:0>{{ $t('message.no') }}</template>
        <template v-slot:1>{{ $t('message.yes') }}</template>
      </toggle>
    </div>
    <transition name="flixFadeIn">
      <div :key="toggleWebinar" class="flix-form-group" v-if="toggleWebinar == $t('message.yes')">
        <h4 class="flix-html-h4">{{ $t('message.webinarSystem') }}</h4>
        <select class="flix-form-control" v-model="webinar">
          <option value="bookingflix">Bookingflix (BETA-Version)</option>
        </select>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object,
    callback: Function
  },
  data () {
    return {
      toggleWebinar: false,
      webinar: false
    }
  },
  methods: {
    getWebinar () {
      if (this.data.webinar) {
        this.toggleWebinar = this.$t('message.yes')
        this.webinar = this.data.webinar
      } else {
        this.toggleWebinar = this.$t('message.no')
        this.webinar = false
      }
      this.callback(this.webinar)
    },
    changeWebinar (data) {
      if (this.toggleWebinar === this.$t('message.yes')) {
        this.toggleWebinar = this.$t('message.no')
        this.webinar = false
        this.callback(this.webinar)
      } else {
        this.toggleWebinar = this.$t('message.yes')
        this.webinar = 'bookingflix'
        this.callback(this.webinar)
      }
    }
  },
  mounted () {
    this.getWebinar()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
