import { render, staticRenderFns } from "./webinar.vue?vue&type=template&id=448dcbda&scoped=true"
import script from "./webinar.vue?vue&type=script&lang=js"
export * from "./webinar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "448dcbda",
  null
  
)

export default component.exports